import styled from 'styled-components';
import CtaSection from '../../components/elements/CtaSection';
import ShadowBox from '../../components/elements/ShadowBox';
import PageSection from '../../components/layout/PageSection';
import { WrapperEl } from '../shared/layout';
import { respondTo } from '../shared/breakpoints';
import { dotsBlue, greyBorder, greyCopy, pink } from '../shared/colors';

export const VhlAboutSection = styled(PageSection)`
    padding: 37px 0;
    ${respondTo.sm`
        padding: 68px 0 52px 0;
    `}
    .pageSectionWrapper{
        padding-bottom: 18px;
        border: 0;
        ${respondTo.sm`
            padding: 0 30px 68px;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                width: calc(100% + 24px);
                bottom: 0px;
                height: 1px;
                background-color: ${greyBorder}
            }
        `}
        ${respondTo.md`
            padding: 0 30px 50px;
        `}
    }
    h2{
        font-family: 'noto-serif';
        font-size: 27px;
        line-height: 38px;
        color: ${dotsBlue};
        margin-bottom: 19px;
        ${respondTo.sm`
            margin-bottom: 25px;
        `}
    }
`;

export const VhlAboutCopy = styled.p`
    font-size: 17px;
    line-height: 26px;
    color: ${greyCopy};
    margin-bottom: 32px;
`;

export const VhlSymptomGraphicContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 0;
    ${respondTo.sm`
        flex-direction: row;
        padding-left: 25px;
    `}
`;

export const VhlSymptomGraphic = styled(ShadowBox)`
    padding: 35px 26px 14px 21px;
    justify-content: center;
    display: flex;
    margin-top: 12px;
    box-shadow: 10px 13px 0px ${pink};
    margin-left: -16px;
    margin-right: -16px;
    flex-direction: column;
    ${respondTo.sm`
        padding: 25px 76px 22px 72px;
        margin: 0px;
        box-shadow: 9px 9px 0px ${pink};
        width: 100%;
        gap: 1vw;
        flex-direction: row;
        ul {
            margin-top: 20px;
        }
    `}
`;

export const LearnMoreSection = styled(CtaSection)`
    i {
        font-style: italic;
    }
`;

export const VhlSignSection = styled(CtaSection)`
   padding: 0;
   .ctaContentContainer {
    div {
        align-self: center;
    }
    img {
        margin-top: 0;
    }
   }
       .pageSectionWrapper{
        padding-bottom: 18px;
        border: 0;
        ${respondTo.sm`
            padding: 0 30px 68px;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                width: calc(100% + 24px);
                bottom: 0px;
                height: 1px;
                background-color: ${greyBorder}
            }
        `}
        ${respondTo.md`
            padding: 0 30px 50px;
        `}
    }
`;
