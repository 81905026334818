import React from 'react';
import Hero from '../components/elements/Hero';
import vhlCopy from '../copy/what-is-vhl';
import { H2 } from '../styles/shared/typography';
import * as s from '../styles/pages/what-is-vhl';
import NumberList from '../components/elements/NumberList';
import TalkToDoctorShortSection from '../components/elements/TalkToDoctorShortSection';
import LeftAndRightButtonSection from '../components/elements/LeftAndRightButtonSection';
import PageSeo from '../components/elements/Seo/PageSeo';

const WhatIsPage = () => {
  return (
    <>
    <PageSeo pageTitle={ vhlCopy.pageSeoTitle } pageDescription={ vhlCopy.pageDescription } pageKeywords={ vhlCopy.pageKeywords }>
      <script type="application/ld+json">{`"@context":"http://schema.org/","url":"https://www.coulditbevhl.com/what-is-vhl/","@type":"MedicalWebPage","name":"What is VHL disease?","description":"Von Hippel-Lindau (VHL) disease is a rare condition caused by a change (called a mutation) in a gene."`}</script>
      <script type="application/ld+json">{`"@context":"http://schema.org/","url":"https://www.coulditbevhl.com/what-is-vhl/","@type":"MedicalWebPage","name":"What are the signs and symptoms of VHL disease?","description":"VHL disease affects different people in different ways—there isn’t one sign or symptom that everyone with VHL disease has."`}</script>
      <script type="application/ld+json">{`"@context":"http://schema.org/","url":"https://www.coulditbevhl.com/what-is-vhl/","@type":"MedicalAudience","audienceType":"Patient","geographicArea":"This site is intended only for residents of the United States, its territories, and Puerto Rico."`}</script>
      <link rel="canonical" href="https://www.coulditbevhl.com/what-is-vhl/"/>
    </PageSeo>
      <Hero {...vhlCopy.hero}/>
      <s.VhlAboutSection narrow>
        <H2>{vhlCopy.aboutSection.title}</H2>
        <s.VhlAboutCopy>{vhlCopy.aboutSection.copy}<strong>{vhlCopy.aboutSection.copyBold}</strong></s.VhlAboutCopy>
        <s.VhlAboutCopy as="h5"><strong>{vhlCopy.aboutSection.graphicTitle}</strong></s.VhlAboutCopy>
        <s.VhlSymptomGraphicContainer>
          <s.VhlSymptomGraphic shadow='pink'>
            <NumberList items = {vhlCopy.aboutSection.graphicLabels} />
            <img src={vhlCopy.aboutSection.graphic} alt={vhlCopy.aboutSection.graphicAlt}/>
          </s.VhlSymptomGraphic>
        </s.VhlSymptomGraphicContainer>
      </s.VhlAboutSection>
      <s.VhlSignSection {...vhlCopy.signsSection} />
      <s.LearnMoreSection {...vhlCopy.learnMoreSection} />
      <TalkToDoctorShortSection />
      <LeftAndRightButtonSection {...vhlCopy.leftAndRightButton} />
    </>
  )
}

export default WhatIsPage;
