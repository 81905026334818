import React from 'react';
import * as s from './styles';


const NumberList = ({items, className}) => {
  return (
    <s.NumberList className={className}>
        {items.map((item, i) => (
            <s.ListItem key={i}>
                <s.ListNumber>{i + 1}</s.ListNumber>
                <s.ListLabel>{item}</s.ListLabel>
            </s.ListItem>
        ))}
    </s.NumberList>
  )
}

export default NumberList;
