import React from 'react';
import * as s from './styles';


const ShadowBox = ({className, children, shadow}) => {
  return (
    <s.ShadowBox className={className} shadow={shadow}>
        {children}
    </s.ShadowBox>
  )
}

export default ShadowBox;
