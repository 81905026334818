import styled from 'styled-components';
import { dotsBlue, dotsPurple, greyCopy, white} from '../../../styles/shared/colors';
import { respondTo } from '../../../styles/shared/breakpoints';
import { WrapperEl } from '../../../styles/shared/layout';
import ShadowBox from '../ShadowBox';
import { breakpoints } from '../../../styles/shared/breakpoints';

export const DoctorSectionWrapper = styled(WrapperEl)`
    padding: 0 16px;
    margin-top: 40px;
    margin-bottom: 42px;
    ${respondTo.sm`
        padding: 0 60px;
        margin-top: 67px;
        margin-bottom: 57px;
    `}
`;

export const TalkToDoctorSection = styled(ShadowBox)`
    display: flex;
    padding: 20px 0 30px 0;
    gap: 24px;
    flex-direction: column;
    box-shadow: 8px 12px 0px ${dotsPurple};
    border: 3px solid ${dotsBlue};
    img {
        height: 224px;
        @media (min-width: 768px) and (max-width: 900px){
            height: 210px;
        }
        @media (min-width: 900px){
            height: 300px;
        }
    }
    ${respondTo.sm`
        flex-direction: row;
        padding: 30px 33px 30px 0;
        gap: 40px;
        box-shadow: 9px 8px 0px ${dotsPurple};
    `}

    .desktop-only {
        display: none;
        ${respondTo.sm`
            display: block;
        `}
    }
    .mobile-only {
        display: block;
        ${respondTo.sm`
            display: none;
        `}
    }
`;

export const DoctorCopy = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    ${respondTo.sm`
        padding: 0;
    `}
    h2 {
        font-family: 'noto-serif';
        font-size: 27px;
        line-height: 38px;
        color: ${dotsBlue};
    }
    p {
        font-size: 17px;
        line-height: 26px;
        color: ${greyCopy};
        margin-bottom: 24px;
    }
    a { 
        padding: 14px 59px 16px 16px;
        margin: 0 auto;
        width: 100%;
        line-height: 20px;
        ${respondTo.sm`
            margin: 0;
            width: 100%;
            max-width: 295px;
        `}
        &:hover {
            background-color: ${white};
            color: ${dotsBlue};
            .arrow-right {
                filter: none;
            }
        }
    }
`;
