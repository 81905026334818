import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { dotsBlue } from '../../../styles/shared/colors';

export const NumberList = styled.ul`
    column-count: 2;
    ${respondTo.sm`
        column-count: 1;
    `}
`;

export const ListNumber = styled.div`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid ${dotsBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
`;

export const ListItem = styled.li`
    display: flex;
    font-weight: bold;
    color: ${dotsBlue};
    margin-bottom: 18px;
`;

export const ListLabel = styled.p`
    margin-left: 10px;
    font-size: 15px;
    line-height: 21px;
    width: 70%;
    ${respondTo.sm`
        margin-left: 17px;
        font-size: 17px;
        width: auto;
        line-height: 26px;
    `}
`;
