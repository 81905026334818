import React from 'react';
import * as s from './styles';
import { H2 } from '../../../styles/shared/typography';
import copy from '../../../copy/talkToDoctorShort';
import Button from '../Button';

const TalkToDoctorShortSection = ({className}) => {
  return (
    <s.DoctorSectionWrapper className={className}>
          <s.TalkToDoctorSection shadow='purple'>
            <img src={copy.desktopImgSrc} alt={copy.imgAlt} className='desktop-only'/>
            <img src={copy.mobileImgSrc} alt={copy.imgAlt} className='mobile-only'/>
            <s.DoctorCopy>
              <H2>{copy.title}</H2>
              <p>{copy.copy}</p>
              <Button icon='arrow-right' to={copy.buttonTo} data-design-category='button_vd'>{copy.buttonLabel}</Button>
            </s.DoctorCopy>
          </s.TalkToDoctorSection>
      </s.DoctorSectionWrapper>
  );
};


export default TalkToDoctorShortSection;