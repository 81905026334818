import React from 'react';
import * as s from './styles';
import { H1 } from '../../../styles/shared/typography';

const Hero = ({ title, imgSrc, imgAlt, underline, className}) => {
  return (
    <s.Hero grey narrow className={className}>
        <s.HeroContainer alignCenter gap="32px">
        <s.CopyContainer underline={underline}>
          <H1 dangerouslySetInnerHTML={{__html: title}} />
        </s.CopyContainer>

        {imgSrc && <img src={imgSrc} alt={imgAlt}/>}

      </s.HeroContainer>
    </s.Hero>
  );
};


export default Hero;