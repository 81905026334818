import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { dotsBlue, white } from '../../../styles/shared/colors';
import { WrapperEl } from '../../../styles/shared/layout';

export const LeftAndRightButton = styled(WrapperEl)`
    padding: 0 30px 40px 30px;
    ${respondTo.sm`
        padding: 0 60px 60px 60px;
    `}
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 31px;
    ${respondTo.sm`
        flex-direction: row;
    `}
    a {
        padding: 17px 43px 20px 38px;
        .arrow-right, .arrow-left {
            height: 10px;
            filter: none;
        }
        &:hover {
            background-color: ${dotsBlue};
            color: ${white};
            .arrow-right, .arrow-left {
                filter: brightness(0) invert(1);
            }
        }
        ${respondTo.sm`
            a { 
                width: 268px;
            }
        `}
    }
    .right-button {
        margin: 0;
        ${respondTo.sm`
            margin: 0 0 0 auto;
            width: 268px;
        `}
    }
    .left-button {
        margin: 0;
        ${respondTo.sm`
            margin: 0 auto 0 0;
            width: 268px;
        `}
    }
`;