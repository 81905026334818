import styled from 'styled-components';
import { dotsBlue, greyCopy} from '../../../styles/shared/colors';
import { respondTo } from '../../../styles/shared/breakpoints';
import PageSection from '../../layout/PageSection';

export const CtaSection = styled(PageSection)`
    h2 {
        color: ${dotsBlue};
        font-family: 'noto-serif';
        font-size: 27px;
        line-height: 38px;
        margin-bottom: 14px;
        ${respondTo.sm`
            margin-bottom: 34px;
        `}
    }
`;

export const CtaContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${respondTo.sm`
        flex-direction: row;
        gap: 55px;
        align-items: flex-start;
    `}
    img {
        margin-top: 16px;
        ${respondTo.sm`
            margin-top: 0;
        `}
    }
`;

export const CtaCopy = styled.div`
    color: ${greyCopy};
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 17px;
    line-height: 26px;
    em {
        color: ${dotsBlue};
        font-weight: bold;
    }
    ul {
        list-style: disc;
        padding-left: 32px;
        li {
            margin-top: 16px;
            padding-left: 8px;
        }
    }
    p {
        margin-top: 16px;
        &:first-child {
            margin-top: 30px;
            ${respondTo.sm`
                margin-top: -6px;
            `}
        }
    }
`;
