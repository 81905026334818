import React from 'react';
import * as s from './styles';
import { H2 } from '../../../styles/shared/typography';

const CtaSection = ({className, title, graphic, graphicAlt, copy, bottomSection, topSection}) => {
  return (
    <s.CtaSection narrow className={className}>
        <H2>{title}</H2>
        {topSection && <s.CtaCopy dangerouslySetInnerHTML={{__html: topSection}}>
          </s.CtaCopy>}
        <s.CtaContentContainer className='ctaContentContainer'>
          <img src={graphic} alt={graphicAlt} />
          <s.CtaCopy dangerouslySetInnerHTML={{__html: copy}}>
          </s.CtaCopy>
        </s.CtaContentContainer>
        {bottomSection && <s.CtaCopy dangerouslySetInnerHTML={{__html: bottomSection}}>
          </s.CtaCopy>}
    </s.CtaSection>
  );
};


export default CtaSection;