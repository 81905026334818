import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { dotsBlue, dotsPurple, white, pink, dotsPeach, dotsLightBlue, limeGreen } from '../../../styles/shared/colors';

function getShadowColor(shadow) {
    switch(shadow){
        case 'pink':
            return pink;
        case 'peach':
            return dotsPeach;
        case 'purple':
            return dotsPurple;
        case 'green':
            return dotsLightBlue;
        case 'lime':
            return limeGreen;
        default:
            return pink;
        }
}


export const ShadowBox = styled.section`
    border: 3px solid ${dotsBlue};
    position: relative;
    box-shadow: 9px 11px 0px ${({shadow}) => getShadowColor(shadow)};
    ${respondTo.sm`
        box-shadow: 14px 17px 0px ${({shadow}) => getShadowColor(shadow)};
    `}
`;

export const Circle1 = styled.span`
    width: 12px;
    height: 12px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    transform: translate(50%,-50%);
    border: 3px solid ${dotsBlue};
    background: ${white};
    display: none;
`;

export const Circle2 = styled.span`
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    transform: translate(-50%,-50%);
    border: 3px solid ${dotsBlue};
    background: ${white};
    display: none;
`;

export const Circle3 = styled.span`
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 50%;
    transform: translate(-50%,50%);
    border: 3px solid ${dotsBlue};
    background: ${white};
    display: none;
`;

export const Circle4 = styled.span`
    width: 12px;
    height: 12px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    transform: translate(50%,50%);
    border: 3px solid ${dotsBlue};
    background: ${white};
    display: none;
`;