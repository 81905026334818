import vhlHero from '../assets/icons/icon-magnifying-glass.svg';
import vhlAboutGraphic from '../assets/images/Illustrations_Human_Body.svg';
import vhlSignsGraphic from '../assets/images/Body_Tumors.svg';
import learnMoreGrapic from '../assets/images/icon_monitor_pink.svg';

const whatIsVhlCopy = {
  pageSeoTitle: 'What Is Von Hippel-Lindau (VHL) Disease?',
  pageDescription: 'Von Hippel-Lindau (VHL) disease is a rare condition caused by a mutation in a gene. Learn about signs and symptoms of VHL disease here.',
  pageKeywords: 'what is vhl, what is von hippel-lindau disease',

  hero: {
    title: 'What is VHL disease?',
    imgSrc: vhlHero,
    imgAlt: '',
    underline: 'pink',
  },

  aboutSection: {
    title: 'Von Hippel-Lindau (VHL) disease is a rare condition caused by a change (called a mutation) in a gene.',
    copy: 'People with this gene mutation are at risk of developing recurrent cysts and tumors in multiple organs over time. While some can be cancerous, most VHL disease-associated tumors aren’t cancerous, but they can still grow and cause serious problems. ',
    copyBold: 'VHL disease is rare and affects about 10,000 people in the United States.',
    graphicTitle: 'VHL disease-associated cysts and tumors can develop in various parts of the body:',
    graphicLabels: [
      'Brain and brain stem',
      'Inner ears',
      'Adrenal glands',
      'Pancreas',
      'Reproductive system',
      'Eyes',
      'Spine',
      'Liver',
      'Lungs',
      'Kidneys',
    ],
    graphic: vhlAboutGraphic,
    graphicAlt: 'Image of Locations Where VHL Disease-Associated Cysts and Tumors May Appear',
  },

  signsSection: {
    title: 'What are the signs and symptoms of VHL disease?',
    copy: `<p>VHL disease affects different people in different ways—there isn’t one sign or symptom that everyone with VHL disease has.</p><p>Part of the reason that VHL disease symptoms can vary from person to person is because they don’t affect one organ exclusively. Rather, people with VHL disease have an increased risk of developing multiple, recurrent cysts or tumors throughout the body.</p>`,
    graphic: vhlSignsGraphic,
    graphicAlt: '',
  },
    learnMoreSection: {
        title: ' Learn more about living with VHL disease ',
        graphic: learnMoreGrapic,
        graphicAlt: '',
        copy:`<p>Visit these organizations and resources for more information*:</p>
        <p><strong>The VHL Alliance </strong></p>
        <p>Founded in 1993, the VHL Alliance is the preeminent resource for patients, caregivers, researchers, and the medical community.</p>
        <p><strong>The National Organization for Rare Diseases (NORD)</strong></p>
        <p>Since 1983, NORD has been a national steward and a steadfast partner helping those who battle and care for rare disease feel seen, heard, supported, and connected.</p>
        <p><strong>The Genetic and Rare Diseases (GARD) Information Center</strong></p>
        <p>The National Institutes of Health (NIH) GARD information center is a public health resource for people living with a rare disease and their families. It provides free access to information in English and Spanish.</p>
        <p><strong>Disease InfoSearch</strong></p>
        <p>Disease InfoSearch serves as a resource for curated and crowd-sourced disease information to help individuals access information. On Disease InfoSearch, information is aggregated from a variety of resources including journal articles, clinical trials, and news and events.</p>
        <p><i>*The organizations listed above are not affiliated with Merck. Merck does not review or control their content. The list does not constitute endorsement by Merck.</i></p>
        `,
    },
  leftAndRightButton: {
    right: {
      label: 'VHL and genetics',
      to: '/vhl-and-genetics/',
      shadow: 'lime'
    }
  }

};


export default whatIsVhlCopy;
