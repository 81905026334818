import React from 'react';
import * as s from './styles';
import Button from '../Button';

const LeftAndRightButtonSection = ({left, right, className}) => {
  return (
      <s.LeftAndRightButton className={className}>
        {left && <Button type='secondary' icon='arrow-left' className='left-button' to={left.to} shadow={left.shadow} data-design-category='button_vd'>{left.label}</Button>}
        {right && <Button type='secondary' icon='arrow-right' className='right-button' to={right.to} shadow={right.shadow} data-design-category='button_vd'>{right.label}</Button>}
      </s.LeftAndRightButton>
  )
}

export default LeftAndRightButtonSection;
