import styled from 'styled-components';
import PageSection from '../../layout/PageSection';
import { dotsBlue, dotsLightBlue, dotsPeach, pink, limeGreen, dotsPurple } from '../../../styles/shared/colors';
import { respondTo } from '../../../styles/shared/breakpoints';

function getBorderColor(underline) {
    switch(underline){
        case 'pink':
            return pink;
        case 'peach':
            return dotsPeach;
        case 'green':
            return dotsLightBlue;
        case 'lime':
            return limeGreen;
        case 'purple':
            return dotsPurple;
        default:
            return pink;
        }
}

export const Hero = styled(PageSection)`
padding: 0;
    img {
        width: 180px;
        height: 180px;
        ${respondTo.sm`
            width: 205px;
            height: 201px;
        `}
    }
`;


export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
  gap: 27px;
  ${respondTo.sm`
    flex-direction: row;
  `}
`;


export const CopyContainer = styled.div`
  color: ${dotsBlue};
  width: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 35px;
    line-height: 45px;
    font-family: 'noto-serif';
    position: relative;
    margin-bottom: 16px;
    border-bottom: 5px solid ${({underline}) => getBorderColor(underline)};
    text-align: center;
    width: 71%;
    padding-bottom: 18px;
    ${respondTo.sm`
        font-size: 45px;
        line-height: 58px;
        width: auto;
        border-bottom: 7px solid ${({underline}) => getBorderColor(underline)};
    `}
  }
`;

export const HeroUnderline = styled.span`
  height: 7px;
  margin-bottom: 25px;
  background-color: ${pink};
`;